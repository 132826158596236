import React from "react"

export interface  ArenaRankingPlayerCardProps {
  rank: number,
  pseudo: string,
  points: number,
  isMe: boolean,
}
const ArenaRankingPlayerCard: React.FunctionComponent<ArenaRankingPlayerCardProps> =
    ({ rank, pseudo, points, isMe }) => {

        return (
            <div className={isMe ? "arena-ranking-player-card me-highlighted" : "arena-ranking-player-card"}>
                <div className="arena-ranking-player-card-rank">
                    <span>{rank}</span>
                </div>

                <div className="arena-ranking-player-card-pseudo">
                    {isMe && <span className="me-highlighted">{pseudo}</span>}
                    {!isMe && <span>{pseudo}</span> }
                </div>

                <div className="arena-ranking-player-card-points">
                    <span>
                        {points}
                        {" "}
                        pts
                    </span>
                </div>
            </div>
        )
    }

export default ArenaRankingPlayerCard

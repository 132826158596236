import React, { ReactElement, useEffect, useState } from "react"
import { ArenaBase } from "../../../store/services/arenaService"
import { ArenaRanks, rankingService } from "../../../store/services/rankingService"
import { Utilisateur, utilisateurService } from "../../../store/services/utilisateurService"
import { useStoreSelector } from "../../../store/Hook"
import { getUser } from "../../../store/slices/utilisateurSlice"
import ArenaRankingPlayerCard from "../../cards/ArenaRankingPlayerCard"
import ArenaRankingAvatarCard from "../../cards/ArenaRankingAvatarCard"
import { MapModalStyles, MapModalSwitchAction } from "../MapModal"

export interface RankingModalProps {
  arena?: ArenaBase,
  onSwitchBack: MapModalSwitchAction,
}

export const ArenaRankingStyle: MapModalStyles = {
    overlay: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        minHeight: "100%",
        maxHeight: "100%",
        margin: "0",
        backgroundColor: "none",
    },
    content: {
        inset: "0",
        opacity: "0.95",
        height: "100%",
        backgroundColor: "#09273F",
        padding: "0",
    },
}

const ArenaRanking: React.FunctionComponent<RankingModalProps> = ({ arena, onSwitchBack }) => {

    const [getArenasRanks] = rankingService.useGetArenaRankingMutation()
    const [arenaRanks, setArenaRanks] = useState<ArenaRanks[]>([])

    const [getUserAvatar] = utilisateurService.useGetUtilisateurAvatarMutation()

    const currentUser = useStoreSelector(getUser)

    const [ranking, setRanking] = useState<ReactElement[]>([])
    const [podium, setPodium] = useState<ReactElement[]>([])

    useEffect(() => {
        if (!arenaRanks || arenaRanks.length === 0) {
            return
        }
        const tempRanking = []
        for (const rank of arenaRanks) {
            // if (rank.joueur_uuid === currentJoueurUid){ console.log("OUI je suis dans la liste : ", rank.pseudo)}
            tempRanking.push(<ArenaRankingPlayerCard key={`ranking_${tempRanking.length}`} rank={rank.rank}
                pseudo={rank.username} points={rank.points}
                isMe={rank.user_id === currentUser.id} />)
        }
        setRanking(tempRanking)
    }, [arenaRanks, currentUser.id])

    useEffect(() => {
        if (!arenaRanks || arenaRanks.length === 0) {
            return
        }
        const joueursPromises: Promise<Utilisateur>[] = []

        for (let i = 0; i < 3 && i < arenaRanks.length; i++) {
            joueursPromises.push(getUserAvatar(arenaRanks[i].user_id).unwrap())
        }

        Promise.all(joueursPromises)
            .then(joueurs => {
                const tempPodium = []
                let tempPos = "b"

                for (let i = 0; i < 3 && i < joueurs.length; i++) {
                    if (i === 1) {
                        tempPos = "a"
                    } else if (i === 2) {
                        tempPos = "c"
                    }
                    tempPodium.push(
                        <ArenaRankingAvatarCard key={`podium_${tempPodium.length}`}
                            url={joueurs[i]?.avatar?.image?.url}
                            options={{ tShirtColor: "#477B9C", etat: "win" }}
                            rank={i}
                            order={tempPos} />)
                }
                setPodium(tempPodium)
            })
    }, [getUserAvatar, arenaRanks])

    useEffect(() => {
        if (!arena) {
            return
        }

        if (arena.nom) {
            getArenasRanks(arena.id)
                .unwrap()
                .then((arenaRanks) => {
                    setArenaRanks(arenaRanks)
                })
        }
    }, [arena, getArenasRanks])

    function closeModal(event: React.MouseEvent | React.KeyboardEvent): void {
        setRanking([])
        setPodium([])
        setArenaRanks([])
        onSwitchBack(event)
    }

    return (
        <>
            <button className='ranking-modal-close' onClick={closeModal}>X</button>

            <div className='ranking-modal-title'>
                <div className='ranking-modal-arena-name'>{arena?.nom}</div>
                ARENA
            </div>
            <div className='ranking-arena'>
                <div className='podium' id='flex'>
                    {podium}
                </div>

                <div className='arena-ranking-cards'>
                    {ranking}
                </div>
            </div>
        </>
    )
}
export default ArenaRanking

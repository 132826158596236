import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { utilisateurService } from "../store/services/utilisateurService"
import { useStoreDispatch, useStoreSelector } from "../store/Hook"
//import Header from "../components/Header"
import { getUser, login } from "../store/slices/utilisateurSlice"
import { usePreviousQuery, usePreviousQueryValue } from "../hooks/usePreviousQuery"
import eyeOnIcon from "../img/icons/eye_on.svg"
import eyeOffIcon from "../img/icons/eye_off.svg"


const Login: React.FunctionComponent = () => {

    const previous: string = usePreviousQuery()
    const previousValue: string = usePreviousQueryValue()

    const dispatch = useStoreDispatch()
    const navigate = useNavigate()
    const [error, setError] = React.useState(undefined)

    const user = useStoreSelector(getUser)
    
    const [utilisateurData, setUtilisateurData] = React.useState({
        identifier: "",
        password: "",
    })

    const [loginUtilisateur] =
        utilisateurService.useLoginUtilisateurMutation()

    const [showPasswordLogin, setShowPasswordLogin] = React.useState(false)
    
    const handlePassword = () => {
        if (showPasswordLogin) {
            setShowPasswordLogin(false)
        } else {
            setShowPasswordLogin(true)
        }
    }

    useEffect(() => {
        if (!user) {
            return
        }
        setUtilisateurData({
            identifier: user.username,
            password: "",
        })
    }, [user])

    const handleSubmit = async (e) => {
        e.preventDefault()    
        loginUtilisateur(utilisateurData)
            .unwrap()
            .then(response => {
                dispatch(login(response))

                if (response.user.email !== response.user.username) {
                    if (previous) {
                        navigate(previousValue)
                    } else {
                        navigate("/carte")
                    }
                } else {
                    navigate(`/playerSelection${previous}`)
                }
            })
            .catch(e => {
                if (e.status === 400 && e?.data?.error.name === "ValidationError") {
                    setError("Mail ou mot de passe incorrect")
                }
            })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setUtilisateurData({ ...utilisateurData, [name]: value })
    }

    function handleRegister() {
        navigate("/register")
    }

    function handleReset()  {
        navigate("/forgot-password")
    }

    return (
        <div className="BabyfootArenas-body">

            { /* <Header headerHeight={10} /> */ }

            {<div className="home-gape-30"></div>}

            <div className="home-link">

                <form onSubmit={handleSubmit} className="register-form">
                    {/* <div className="form-content"> */}
                    <label htmlFor="identifier">
                        Mail
                    </label>
                    <input
                        type="text"
                        name="identifier"
                        onChange={(e) => handleChange(e)}
                        required />
                    {/* </div> */}
                    <div className="form-content">
                        <label className="label-form" htmlFor="password">
                            Password
                        </label>
                        <input
                            type={showPasswordLogin ? "text" : "password"}
                            name="password"
                            onChange={(e) => handleChange(e)}
                            required
                            className="form-control rounded-0" />
                        <div className="button-showPassword" onClick={handlePassword}>
                            {!showPasswordLogin && <img src={eyeOnIcon} alt="show" className="eye"></img> }
                            {showPasswordLogin && <img src={eyeOffIcon} alt="show" className="eye"></img> }
                        </div>
                    </div>
                    {error && <p className="register-error">{error}</p>}

                    <button type="submit" className="button-submit">
                        Se connecter
                    </button>
                </form>

                <button type="submit" onClick={handleReset}>
                    Mot de passe oublié ?
                </button>

                <button type="submit" onClick={handleRegister}>
                    Pas encore inscrit.e ?
                </button>

            </div>
        </div>
    )
}

export default Login

import React from 'react';
import { utilisateurService } from '../store/services/utilisateurService';
import { Field, Formik } from 'formik';
import { object, string } from 'yup';
import { useNavigate } from 'react-router-dom';
//import Header from '../components/Header';

interface ForgotPasswordForm {
    email: string,
}

const ForgotPassword: React.FunctionComponent = () => {

    const schema = object().shape({
        email: string()
            .required("Requis")
    })

    const [error, setError] = React.useState(undefined)

    const [forgotPassword] =
        utilisateurService.useForgotPasswordMutation()

    const navigate = useNavigate()

    const handleSubmit = async (value: ForgotPasswordForm) => {
        console.log(value)
        forgotPassword({
            email: value.email
        })
            .unwrap()
            .then(() => {
                setError(undefined)
                navigate('/login')
            })
            .catch(e => {
                if (e.status === 400 && e?.data?.error.name === "ValidationError") {
                    setError("Email non valide.")
                } else {
                    setError("Une erreur s'est produite, veuillez réessayer ultérieurement.")
                }
            })
    }

    return (
        <div className="BabyfootArenas-body">

            { /* <Header headerHeight={10} /> */ }

            <div className="home-gape"></div>

            <div className="home-link">
                <Formik
                    initialValues={{ email: "" }}
                    validationSchema={schema}
                    onSubmit={handleSubmit}>
                    {
                        (props) => {
                            return (
                                <form onSubmit={props.handleSubmit} className='player-selection-form'>
                                    <Field
                                        type='text'
                                        placeholder='Email'
                                        name={"email"} />

                                    {props.errors.email && <p className='register-error'>{props.errors.email}</p>}

                                    {error && <p className='register-error'>{error}</p>}

                                    <button className='button-submit' type='submit'>
                                        Demander un nouveau mot de passe
                                    </button>
                                </form>
                            )
                        }
                    }
                </Formik>
            </div>
        </div>
    )
}

export default ForgotPassword

import { configureStore } from "@reduxjs/toolkit"

import { api } from "./services/api"
import { gameSlice } from "./slices/gameSlice"
import { menuSlice } from "./slices/menuSlice"
import { utilisateurSlice } from "./slices/utilisateurSlice"

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,

        [menuSlice.name]: menuSlice.reducer,

        [utilisateurSlice.name]: utilisateurSlice.reducer,
        [gameSlice.name]: gameSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type StoreDispatch = typeof store.dispatch

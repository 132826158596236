import React from "react"
import minusIcon from "../../img/icons/minus_icon.svg"
import plusIcon from "../../img/icons/plus_icon.svg"

export interface GameScore {
    score_bleu: number,
    score_rouge: number,
}

interface ScoreProps {
    score: number,
    setScore: (number) => void,
    canChange: boolean,
}
const Score: React.FunctionComponent<ScoreProps> = ({ score, setScore, canChange }) => {

    const decreaseScore = () => {
        if (score > 0) { // -11 TO DO : avant calcul de l'elo => on repasse en positif pour le calcul
            setScore(score - 1)
        }
    }

    const increaseScore = () => {
        if (score < 12) { // 11 ?
            setScore(score + 1)
        }
    }

    return (
        <>
            {
                canChange &&
                <img src={minusIcon} className="picto-minus" alt="minus" onClick={() => decreaseScore()}></img>
            }
            <span className="score-number">{score}</span>
            {
                canChange &&
                <img src={plusIcon} className="picto-plus" alt="plus" onClick={() => increaseScore()}></img>
            }
        </>
    )
}

export default Score

import React from "react"
import { QrScanner } from "@yudiel/react-qr-scanner"
import Modal from "react-modal"

export interface QrScannerModalProps {
  open: boolean,
  onClose: () => void,
}

const QrScannerModal: React.FunctionComponent<QrScannerModalProps> = ({ open, onClose }) => {

    function closeModal() {
        if (onClose !== undefined) {
            onClose()
        }
    }

    function onDecode(result: string) {
        if (result.startsWith(`${process.env.REACT_APP_BACK_PROTOCOL_API}://${process.env.REACT_APP_FRONT_URL}`)) {
            window.location.href = result
        }
    }

    return (
        <Modal
            isOpen={open}
            onRequestClose={closeModal}
            contentLabel='QR Code scanner'
            style={
                {
                    overlay: {
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "none",
                    },
                    content: {
                        position: "absolute",
                        left: 0,
                        right: 0,
                        border: "none",
                        background: "none",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        borderRadius: "4px",
                        outline: "none",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    },
                }
            }>
            <button className='qr-modal-close' onClick={() => closeModal()}>X</button>

            <QrScanner
                onDecode={onDecode}
                onError={(error) => console.log(error?.message)} />
        </Modal>
    )
}

export default QrScannerModal

import React from "react"
import { Game, gameService } from "../../store/services/gameService"
import { useStoreDispatch } from "../../store/Hook"
import { useLocation, useNavigate } from "react-router-dom"
import { redirectLogin } from "../../utils/redirection"
import Modal from "react-modal"
import RankingModal from "../modal/RankingModal"
import { showMenu } from "../../store/slices/menuSlice"
import CountdownTimer from "../timer/countdownTimer"
import QrScannerModal from "../modal/QrScannerModal"
import { GameActionEnum } from "./GameUtils"
import { GameScore } from "./Score"

interface GameActionProps {
    userId: number,
    game: Game,
    emitEvent: (event, args) => void,
    scoreRef: GameScore
}

Modal.setAppElement(document.getElementById("root"))

const GameAction: React.FunctionComponent<GameActionProps> = ({ userId, game, emitEvent, scoreRef }) => {

    const dispatch = useStoreDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const [validateTeam] =
        gameService.useValidTeamMutation()

    const [saveScore] =
        gameService.useScoreMutation()

    const [confirmeScore] =
        gameService.useConfirmeScoreMutation()

    const [isRankingOpen, setIsRankingOpen] = React.useState(false)
    const [isQrScannerOpen, setIsQrScannerOpen] = React.useState(false)

    const onRankingStop = () => {
        setIsRankingOpen(false)
        console.log("onRankingStop")
        navigate("/game")
        dispatch(showMenu())
    }

    const onTimerStop = () => {
        console.log("onTimerStop")
        navigate("/game")
    }

    const onQrScannerStop = () => {
        setIsQrScannerOpen(false)
    }

    const validTeam = () => {
        validateTeam({
            gameUid: game.uuid,
        })
            .unwrap()
            .then(() => {
                emitEvent("update", { matchUid: game.uuid, action: GameActionEnum.validTeam })
            })
            .catch(e => {
                if (e.originalStatus === 401) {
                    redirectLogin(navigate, location)
                }
            })
    }

    const saveScoreMatch = () => {
        saveScore({
            gameUid: game.uuid,
            ...scoreRef,
        })
            .unwrap()
            .then(() => {
                emitEvent("update", { matchUid: game.uuid, action: GameActionEnum.updateScore })
            })
            .catch(e => {
                if (e.originalStatus === 401) {
                    redirectLogin(navigate, location)
                }
            })
    }

    const confirmeScoreMatch = (confirmation: boolean) => {
        confirmeScore({
            gameUid: game.uuid,
            confirmation,
        })
            .unwrap()
            .then(() => {
                emitEvent("update", { matchUid: game.uuid, action: GameActionEnum.confirmScore })
            })
            .catch(e => {
                if (e.originalStatus === 401) {
                    redirectLogin(navigate, location)
                }
            })
    }

    const waitForScan = () => {
        if (!game) {
            return (
                <>
                    <span className='middle-place message' onClick={
                        () => {
                            setIsQrScannerOpen(true)
                        }
                    }>
                        Scanner
                        <br />
                        {" "}
                        un terrain
                    </span>
                </>
            )
        }
        return false
    }

    const waitForTeams = () => {
        if (game.equipes[0].users.length === 0 || game.equipes[1].users.length === 0) {
            return (
                <>
                    <CountdownTimer startDate={new Date(game.createdAt)} onTimerEnd={onTimerStop} />
                    <span className='middle-place message'>
                        En attente
                        <br />
                        {" "}
                        de joueur.se
                    </span>
                </>
            )
        }
        return false
    }

    const waitForValidationTeam = () => {
        if (!game.valide) {
            console.log("waitForValidationTeam timer")
            if (game.game_leader.id === userId) {
                return (
                    <div onClick={() => validTeam()}>
                        <CountdownTimer startDate={new Date(game.createdAt)} onTimerEnd={onTimerStop} />
                        <span className='middle-place message'>
                            Valider 
                            {" "}
                            <br />
                            {" "}
                            les équipes
                        </span>
                    </div>
                )
            } else {
                return (
                    <>
                        <CountdownTimer startDate={new Date(game.createdAt)} onTimerEnd={onTimerStop} />
                        <span className='middle-place message'>
                            Attendre 
                            {" "}
                            <br />
                            {" "}
                            la validation
                        </span>
                    </>
                )
            }
        } else {
            return false
        }
    }

    const waitForScore = () => {
        if (!game.score_valide) {
            if (game.game_leader.id === userId) {
                return (
                    <button className='middle-place'
                        onClick={() => saveScoreMatch()}>
                        Entrer 
                        {" "}
                        <br />
                        {" "}
                        le score
                    </button>
                )
            } else {
                return (
                    <span className='middle-place message'>
                        En attente 
                        {" "}
                        <br />
                        {" "}
                        du score
                    </span>
                )
            }
        } else {
            return false
        }
    }

    const waitForValidationScore = () => {
        if (game.score_confirmer === null) {
            if (game.game_leader.id === userId) {
                return (
                    <span className='middle-place message'>
                        Confirmer 
                        {" "}
                        <br />
                        {" "}
                        le score
                        <button className='confirmer-oui'
                            onClick={() => confirmeScoreMatch(true)}>
                            oui
                        </button>
                        <button className='confirmer-non'
                            onClick={() => confirmeScoreMatch(false)}>
                            non
                        </button>
                    </span>
                )
            } else {
                return (
                    <span className='middle-place message'>
                        En attente 
                        {" "}
                        <br />
                        {" "}
                        d'une validation
                    </span>
                )
            }
        } else {
            return false
        }
    }

    const waitForEnd = () => {

        return (
            <button className='middle-place' onClick={
                () => {
                    setIsRankingOpen(true)
                }
            }>
                Terminer
            </button>
        )
    }

    return (
        <>
            {
                waitForScan() ||
            waitForTeams() ||
            waitForValidationTeam() ||
            waitForScore() ||
            waitForValidationScore() ||
            waitForEnd()
            }

            <RankingModal arena={game?.terrain?.arena} open={isRankingOpen} onClose={onRankingStop} />
            <QrScannerModal open={isQrScannerOpen} onClose={onQrScannerStop} />
        </>
    )
}

export default GameAction

import React from "react"

export interface  ArenaRankingCardProps {
  arena: string,
  rank: number,
  onClick: () => void
}
const ArenaRankingCard: React.FunctionComponent<ArenaRankingCardProps> = ({ arena, rank, onClick }) => {

    return (
        <div className="arena-ranking-card" onClick={onClick}>
            <div className="arena-ranking-card-rank">
                <span>{rank}</span>
            </div>

            <div className="arena-ranking-card-name">
                <span>{arena}</span>
            </div>
        </div>
    )
}

export default ArenaRankingCard

import React from "react"
import moment from "moment"

import "moment/locale/fr"
import { Arena } from "../../../store/services/arenaService"
import { MapModalStyles, MapModalSwitchAction } from "../MapModal"

export interface OverlayProps {
  arena: Arena,
  onSwitchDetails: MapModalSwitchAction,
  onSwitchRanking: MapModalSwitchAction,
}

export const ArenaTooltipStyle: MapModalStyles = {
    overlay: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        minHeight: "30%",
        maxHeight: "30%",
        margin: "20px",
        backgroundColor: "none",
    },
    content: {
        inset: "0",
        color: "#09273F",
        // height: "100%",
        backgroundColor: "#fff",
        opacity: "0.95",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        borderRadius: "5px",
        outline: "none",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center",
    },
}


const ArenaTooltip: React.FunctionComponent<OverlayProps> = ({
    arena,
    onSwitchDetails,
    onSwitchRanking,
}) => {
    const getOpening = () => {
        if (!arena) {
            return <></>
        }
        const opening = arena.attributes.ouverture

        const currentDay = moment().format("dddd")
        const currentHour = moment()

        if (opening && opening.length) {
            for (const daily of opening) {
                if (daily.day === currentDay) {
                    for (const horaire of daily.horaire) {
                        const startHour = moment(horaire.start, "HH:mm:ss")
                        const endHour = moment(horaire.end, "HH:mm:ss")

                        if (startHour < currentHour && currentHour < endHour) {
                            return <span className='map-overlay-opening-open'>ouverte</span> // TO DO : demander à Pierre des icônes arena ouverte/ arena fermée
                        }
                    }
                    break
                }
            }
            return <span className='map-overlay-opening-closed'>fermée</span>
        }

        return <></>
    }

    return (
        <>
            <h2>{arena?.attributes.nom}</h2>
            {/* <div className="arena-name-map">ARENA</div> */}
            <div className='map-overlay-content'>
                <div className='map-overlay-address'>
                    <p>{arena?.attributes.adresse.street}</p>
                    <p>
                        {arena?.attributes.adresse.postal_code} 
                        {" "}
                        {arena?.attributes.adresse.city}
                    </p>
                </div>

                <div className='map-overlay-opening'>
                    <p>{getOpening()}</p>
                </div>
            </div>
            <div className='map-overlay-action'>
                <button className='map-overlay-button-more' onClick={onSwitchRanking}>classement</button>
                <button className='map-overlay-button-none' onClick={onSwitchDetails}>voir plus</button>
            </div>
        </>
    )
}

export default ArenaTooltip

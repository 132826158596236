import React, { useMemo } from "react"
import Modal from "react-modal"
import { ArenaBase } from "../../store/services/arenaService"
import ArenaRanking, { ArenaRankingStyle } from "./modal_content/ArenaRanking"

export interface RankingModalProps {
  arena?: ArenaBase,
  open?: boolean,
  onClose?: () => void
}

const RankingModal: React.FunctionComponent<RankingModalProps> = ({ arena, open, onClose }) => {

    const [modalIsOpen, setIsOpen] = React.useState(open)

    useMemo(() => {
        setIsOpen(open)
    }, [open])

    function closeModal() {
        setIsOpen(false)
        if (onClose !== undefined) {
            onClose()
        }
    }

    return (

        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel='Classement'
            style={ArenaRankingStyle}>
            {
                arena && <ArenaRanking onSwitchBack={() => closeModal()} arena={arena}></ArenaRanking>
             || <></>
            }
        </Modal>
    )
}
export default RankingModal

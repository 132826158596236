import React from "react"
import { Route, Routes } from "react-router-dom"

import Footer from "./components/Footer"
import Home from "./pages/Home"
import PlayerSelection from "./pages/PlayerSelection"
import Carte from "./pages/Carte"
import Profil from "./pages/Profil"
import Register from "./pages/Register"
import Login from "./pages/Login"
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from "./pages/ForgotPassword"
import GameBoard from "./pages/GameBoard"
import PrivateRoute from "./components/route/privateRoute"
import Cgu from "./pages/Cgu"

const BabyfootArenas: React.FunctionComponent = () => {

    return (
        <div className="BabyfootArenas">

            <Routes>
                <Route path="/" element={
                    <Home />
                } />
                <Route path="register" element={
                    <Register />
                } />
                <Route path="login" element={
                    <Login />
                } />
                <Route path="forgot-password" element={
                    <ForgotPassword />
                } />
                <Route path="reset-password" element={
                    <ResetPassword />
                } />
                <Route path="/cgu" element={
                    <Cgu />
                } />

                <Route path="playerSelection" element={
                    <PrivateRoute>
                        <PlayerSelection />
                    </PrivateRoute>
                } />
                <Route path="game" element={
                    <PrivateRoute>
                        <GameBoard />
                    </PrivateRoute>
                } />
                <Route path="join/:terrainUid/:color" element={
                    <PrivateRoute>
                        <GameBoard />
                    </PrivateRoute>
                } />
                <Route path="carte" element={
                    <PrivateRoute>
                        <Carte />
                    </PrivateRoute>
                } />
                <Route path='*' element={
                    <PrivateRoute>
                        <Profil />
                    </PrivateRoute>
                } />
            </Routes>

            <Footer />
        </div>
    )
}

export default BabyfootArenas

import Map from "ol/Map"
import TileLayer from "ol/layer/Tile"
import { OSM } from "ol/source"
import { View } from "ol"
import { fromLonLat, get } from "ol/proj"
import { Icon, Style } from "ol/style"
import image_location from "../img/location_map.png"
import image_location_clicked from "../img/location_map_clicked.png"
import VectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector"
import { GeoJSON } from "ol/format"
import { Arena } from "../store/services/arenaService"
import { SelectEvent } from "ol/interaction/Select"
import { Select } from "ol/interaction"
import { click, pointerMove } from "ol/events/condition"

const enum zIndexLayer {
  base = 0,
  arena = 1,
  hover = 10,
}

const base_arena = new Style({
    image: new Icon({
        anchor: [0.5, 1],
        src: image_location,
        scale: 0.75,
    }),
})

export const selected_arena = new Style({
    image: new Icon({
        anchor: [0.5, 1],
        src: image_location_clicked,
        scale: 0.75,
    }),
    zIndex: zIndexLayer.hover,
})

const initialiseMap = () => {
    const map = new Map({
        layers: [
            new TileLayer({ source: new OSM(), zIndex: zIndexLayer.base }),
        ],

        controls: [],

        view: new View({
            center: fromLonLat([4.831, 45.755]),
            zoom: 13,
            minZoom: 0,
            maxZoom: 28,
        }),
    })

    // On essaye de centrer la map sur la localisation de l'utilisateur
    if (navigator.geolocation) {
        navigator
            .geolocation
            .getCurrentPosition(
                (position) => {
                    console.log("position", position)
                    map.getView().setCenter(fromLonLat([position.coords.longitude, position.coords.latitude]))
                }
            )
    }

    return map
}

const initialiseInteractions = (
    map: Map,
    onMapSelect: (e: SelectEvent) => void,
) => {
    const clickSelect = new Select({
        condition: click,
        style: selected_arena,
    })

    clickSelect.on("select", (e: SelectEvent) => {
        onMapSelect(e)
        map.getView().setCenter(e.mapBrowserEvent.coordinate)
    })

    // Interaction sur le click
    map.addInteraction(clickSelect)

    // Interaction sur le hover
    map.addInteraction(new Select({
        condition: pointerMove,
        style: selected_arena,
    }))
}

export const getMap = (
    target: HTMLDivElement | string,
    onMapSelect: (e: SelectEvent) => void,
) => {
    const map = initialiseMap()

    initialiseInteractions(map, onMapSelect)
    map.setTarget(target)
    return map
}

export const addArenaLayer = (map: Map, arenas: Arena[]) => {
    const arenaLayer = new VectorLayer({
        source: new VectorSource({
            features: new GeoJSON().readFeatures({
                "type": "FeatureCollection",

                "features": arenas.filter(arena => arena.attributes.visible).map((arena) => ({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [arena.attributes.longitude, arena.attributes.latitude],
                    },
                    properties: {
                        arena: arena,
                    },
                })),

            }, {
                featureProjection: get("EPSG:3857"),
            }),
        }),
        style: base_arena,
        zIndex: zIndexLayer.arena,
    })

    map.addLayer(arenaLayer)
}

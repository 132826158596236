import React, { useEffect, useRef, useState } from "react"
import { io } from "socket.io-client"
import { hideMenu, showMenu } from "../store/slices/menuSlice"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import GameAction from "../components/Game/GameAction"
import { useStoreDispatch, useStoreSelector } from "../store/Hook"
import { Game, gameService } from "../store/services/gameService"
import { getUser, setElo } from "../store/slices/utilisateurSlice"
import MenuNavigation from "../components/MenuNavigation"
import EquipeCard from "../components/Game/EquipeCard"
import { redirectLogin } from "../utils/redirection"
import { GameScore } from "../components/Game/Score"
import { GameActionEnum } from "../components/Game/GameUtils"
import GameMessageModal, { GameMessageModalConfig } from "../components/modal/GameMessageModal"

const GameBoard: React.FunctionComponent =      () => {

    const { terrainUid, color } = useParams()

    const dispatch = useStoreDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [game, setGame] = React.useState<Game>(undefined)

    const [isMessageModalOpen, setIsMessageModalOpen] = React.useState<boolean>(false)
    const [messageModalConfig, setMessageModalConfig] = React.useState<GameMessageModalConfig>({})

    const currentUser = useStoreSelector(getUser)

    const connectionInterval = useRef<NodeJS.Timer>(undefined)

    const [scoreRef, setScoreRef] = useState<GameScore>({
        score_bleu: 0,
        score_rouge: 0,
    })

    const openModalMessage = (message: string, config: GameMessageModalConfig = {}) => {
        setMessageModalConfig({ ...config, message })
        setIsMessageModalOpen(true)
    }

    const closeMessageModal = () => {
        setIsMessageModalOpen(false)
        setMessageModalConfig({})
    }

    const [socket] = useState(
        io(`${process.env.REACT_APP_BACK_PROTOCOL_API}://${process.env.REACT_APP_WEBSOCKET_URL}`, {
            reconnectionDelay: 1000,
            reconnection: true,
            reconnectionAttempts: 10,
            transports: ["websocket"],
            agent: false,
            upgrade: true,
            rejectUnauthorized: false,
            autoConnect: false,
        })
    )

    const [joinGame] =
        gameService.useJoinGameMutation()
    const [quitGame] =
        gameService.useQuitGameMutation()

    useEffect(() => {
        if (game?.uuid === undefined) {
            if (currentUser.id === undefined) {
                navigate("/register")
            } else {
                return
            }
        }

        const onConnect = () => {
            if (game?.uuid !== undefined && currentUser.id !== undefined) {
                console.log("socket join")
                socket.emit("join", {
                    userId: currentUser.id,
                    matchUid: game.uuid,
                })
            }
        }

        const onUpdateGame = async (data) => {
            const match: Game = data.match
            const action: GameActionEnum = data.action
            const target: "all" | number = data.target

            if (action === GameActionEnum.quit) {
                if (target === "all") {
                    openModalMessage("Un joueur a quitté la partie. Celle-ci est annulée.")
                    navigate("/game")
                } else if (target === currentUser.id) {
                    openModalMessage("Vous avez été exclu.e par le capitaine de la partie.")
                    navigate("/game")
                }
            }
            setGame(match)
            // updating elo
            if (match.score_confirmer) {
                match.equipes.forEach(equipe => {
                    equipe.users.forEach(user => {
                        if (user.id === currentUser.id) {
                            console.log(user)
                            dispatch(setElo(user.elo))
                        }
                    })
                })
            }
        }

        const onGameClosed = () => {
            socket.disconnect()
            openModalMessage("Vous avez été déconnecté.e.")
            navigate("/game")
        }

        socket.on("connect", onConnect)
        socket.on("update-game", onUpdateGame)
        socket.on("game-closed", onGameClosed)
        socket.connect()

        return () => {
            socket.off("connect", onConnect)
            socket.off("update-game", onUpdateGame)
            socket.off("game-closed", onGameClosed)
        }
    }, [socket, game?.uuid, currentUser.id, dispatch, navigate])

    useEffect(() => {
        if (game?.uuid === undefined || game?.score_bleu === null || game?.score_rouge === null) {
            return
        }

        setScoreRef({
            score_rouge: game.score_rouge,
            score_bleu: game.score_bleu,
        })

    }, [game?.uuid, game?.score_rouge, game?.score_bleu])

    useEffect(() => {
        if (location.pathname.startsWith("/game") || !currentUser.id || !terrainUid || !color) {
            setGame(undefined)
            clearInterval(connectionInterval.current)
            dispatch(showMenu())
        }
    }, [location.pathname, currentUser.id, terrainUid, color, dispatch])

    useEffect(() => {
        if (game?.uuid === undefined || !socket.connected) {
            return
        }

        clearInterval(connectionInterval.current)
        connectionInterval.current = setInterval(() => {
            if (!socket.connected) {
                socket.connect()
            }
        }, 1000)
    }, [game, socket, connectionInterval, currentUser.id])

    useEffect(() => {
        if (!location.pathname.startsWith("/game") && terrainUid && color && game?.uuid === undefined) {
            joinGame({
                terrainUid,
                color,
            })
                .unwrap()
                .then(response => {
                    setGame(response)
                    dispatch(hideMenu())
                })
                .catch(e => {
                    if (e.status === 401) {
                        redirectLogin(navigate, location)
                    } else if (e.status === 429) {
                        return
                    }
                })
        }
    }, [terrainUid, color, game, location, navigate, joinGame, dispatch])

    const emitEvent = (event, args) => {
        if (!socket.connected)
            socket.connect()
        socket.emit(event, args)
    }

    const quit = (quitingUserId: number, username: string) => {
        let self = true
        let message: string
        if (game?.valide) {
            message = "Êtes-vous sûr.e de vouloir quitter la partie ? Celle-ci sera annulée."
        } else if (game?.game_leader.id === currentUser.id && quitingUserId !== currentUser.id) {
            message = `Êtes-vous sûr.e de vouloir exclure ${username} ?`
            self = false
        } else {
            message = "Êtes-vous sûr.e de vouloir quitter la partie ?"
        }

        openModalMessage(message, {
            allowRefusal: true,
            closeAction: () => {
                quitGame({
                    gameUid: game.uuid,
                    userId: quitingUserId,
                })
                    .unwrap()
                    .then(() => {
                        socket.emit("update", {
                            matchUid: game.uuid,
                            action: GameActionEnum.quit,
                            target: quitingUserId,
                        })
                        if (self) {
                            navigate("/game")
                        }
                    })
                    .catch(e => {
                        if (e.originalStatus === 401) {
                            redirectLogin(navigate, location)
                        }
                    })
            },
        })
    }

    const updateScoreBleu = (score) => {
        setScoreRef({
            score_rouge: scoreRef.score_rouge,
            score_bleu: score,
        })
    }

    const updateScoreRouge = (score) => {
        setScoreRef({
            score_bleu: scoreRef.score_bleu,
            score_rouge: score,
        })
    }

    return (

        <div className="BabyfootArenas-body">
            <div className="BabyfootArenas-game">
                <div className="arena">

                    {
                        game?.terrain?.arena.nom ?
                            <div className="arena-name">
                                <div className="player-name-welcome">{game?.terrain?.arena.nom}</div>
                                ARENA
                            </div>
                            :
                            <div className="arena-name">
                                Bienvenue
                                <div className="player-name-welcome">{currentUser?.username}</div>
                            </div>
                    }

                </div>

                <EquipeCard
                    game={game}
                    equipe={
                        game
                            ?.equipes
                            ?.find(equipe => equipe.users.find(u => u.id === currentUser.id) === undefined)
                    }
                    side="top"
                    currentUserid={currentUser.id}
                    clickRemove={quit}
                    updateScoreRouge={updateScoreRouge}
                    updateScoreBleu={updateScoreBleu}
                    score={scoreRef} />

                <GameAction userId={currentUser.id} game={game} emitEvent={emitEvent} scoreRef={scoreRef} />
                
                <div className="bandeau-blanc" />

                <EquipeCard
                    game={game}
                    equipe={
                        game
                            ?.equipes
                            ?.find(equipe => equipe.users.find(u => u.id === currentUser.id) !== undefined)
                    }
                    side="bottom"
                    currentUserid={currentUser.id}
                    clickRemove={quit}
                    updateScoreRouge={updateScoreRouge}
                    updateScoreBleu={updateScoreBleu}
                    score={scoreRef} />

            </div>
            <MenuNavigation />
            <GameMessageModal
                open={isMessageModalOpen}
                onClose={closeMessageModal}
                config={messageModalConfig} />
        </div>
    )
}

export default GameBoard

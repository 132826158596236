import React from "react"
import Modal from "react-modal"

export interface GameMessageModalConfig {
    closeAction?: () => void,
    allowRefusal?: boolean,
    message?: string,
}

export interface GameMessageModalProps {
    open: boolean,
    onClose: () => void,
    config: GameMessageModalConfig,
}
const GameMessageModal: React.FunctionComponent<GameMessageModalProps> = ({ open, onClose, config }) => {

    function closeModal(action: boolean) {
        onClose()
        if (action && config.closeAction !== undefined) {
            config.closeAction()
        }
    }

    return (
        <Modal
            isOpen={open}
            onRequestClose={() => closeModal(false)}
            contentLabel="Game message"
            style={
                {
                    overlay: {
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "none",
                    },
                    content: {
                        position: "absolute",
                        height: "20%",
                        top: "40%",
                        border: "5px solid #0f4f83",
                        background: "#fff",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        borderRadius: "5px",
                        outline: "none",
                        padding: "20px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                    },
                }
            }>

            <span>{ config?.message }</span>
            <div className="gameMessageModalActionDiv">
                {
                    config?.allowRefusal &&
                    <button className="gameMessageModalClose retour" onClick={() => closeModal(false)}>retour</button>
                }
                <button className="gameMessageModalClose action" onClick={() => closeModal(true)}>ok</button>
            </div>
        </Modal>
    )
}

export default GameMessageModal

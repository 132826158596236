import React from "react"
import { useNavigate } from "react-router-dom"
import { utilisateurService } from "../store/services/utilisateurService"
import { useStoreDispatch } from "../store/Hook"
//import Header from "../components/Header"
import { login } from "../store/slices/utilisateurSlice"
import { Field, Formik } from "formik"
import { object, ref, string } from "yup"
import { usePreviousQuery } from "../hooks/usePreviousQuery"
import eyeOnIcon from "../img/icons/eye_on.svg"
import eyeOffIcon from "../img/icons/eye_off.svg"

interface RegisterForm {
    email: string,
    password: string,
    password_confirmation: string,
}

const Register: React.FunctionComponent = () => {
    const schema = object().shape({
        email: string()
            .required("Requis")
            .email("Entrer un mail valide"),
        password: string()
            .required("Requis")
            .min(6, "Le mot de passe doit faire au moins 6 caractères"),
        password_confirmation: string()
            .required("Requis")
            .oneOf([ref("password"), null], "Les mots de passes doivent être identiques"),
    })

    const [showPassword, setShowPassword] = React.useState(false)
    const [showPasswordConfirmation, setShowPasswordConfirmation] = React.useState(false)

    const handlePassword = () => {
        if (showPassword) {
            setShowPassword(false)
        } else {
            setShowPassword(true)
        }
    }

    const handlePasswordConfirmation = () => {
        if (showPasswordConfirmation) {
            setShowPasswordConfirmation(false)
        } else {
            setShowPasswordConfirmation(true)
        }
    }

    const previous: string = usePreviousQuery()

    const dispatch = useStoreDispatch()
    const navigate = useNavigate()
    const [error, setError] = React.useState(undefined)

    const [createUtilisateur] =
        utilisateurService.useCreateUtilisateurMutation()

    const handleSubmit = async (values: RegisterForm) => {

        createUtilisateur({
            email: values.email,
            username: values.email,
            password: values.password,
        })
            .unwrap()
            .then(response => {
                dispatch(login(response))

                navigate(`/playerSelection${previous}`)
            })
            .catch(e => {
                if (e.status === 400 && e?.data?.error.name === "ApplicationError") {
                    setError("Mail déjà utilisé")
                }
                if (e.status === 400 && e?.data?.error.name === "ValidationError") {
                    if (e?.data?.error?.details.errors[0]?.path[0] === "email") {
                        setError("Format de mail invalide")
                    }
                }
            })
    }

    const initialValues = {
        email: "",

        password: "",
        password_confirmation: "",
    }

    return (
        <div className="BabyfootArenas-body">

            { /* <Header headerHeight={10} /> */ }

            {<div className="home-gape-40"></div>}

            <div className="home-link">

                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={handleSubmit}>
                    {
                        (props) => {
                            return (
                                <form onSubmit={props.handleSubmit} className="register-form">
                                    <label htmlFor="email">Mail</label>
                                    <Field
                                        type="email"
                                        name="email" />
                                    {props.errors.email && <p className="register-error">{props.errors.email}</p>}

                                    <div className="form-content">
                                        <label className="label-form" htmlFor="password">Mot de passe</label>
                                        <Field
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            className="form-control rounded-0" />
                                        <div className="button-showPassword" onClick={handlePassword}>
                                            {!showPassword && <img src={eyeOnIcon} alt="show" className="eye"></img> }
                                            {showPassword && <img src={eyeOffIcon} alt="show" className="eye"></img> }
                                        </div>
                                        {
                                            props.errors.password 
                                            && 
                                            <p className="register-error error-pwd"> 
                                                {props.errors.password} 
                                                {" "} 
                                            </p>
                                        }
                                    </div>
                                
                                    <div className="form-content">
                                        <label className="label-form" htmlFor="password_confirmation">
                                            Confirmation du mot de passe
                                        </label>
                                        <Field
                                            type={showPasswordConfirmation ? "text" : "password"}
                                            name="password_confirmation"
                                            className="form-control rounded-0" />
                                        <div className="button-showPassword" onClick={handlePasswordConfirmation}>
                                            {
                                                !showPasswordConfirmation &&
                                                <img src={eyeOnIcon} alt="show" className="eye"></img> 
                                            }
                                            {
                                                showPasswordConfirmation &&
                                                <img src={eyeOffIcon} alt="show" className="eye"></img> 
                                            }
                                        </div>
                                        {
                                            props.errors.password_confirmation &&
                                            <p className="register-error error-pwd">
                                                {props.errors.password_confirmation}
                                            </p>
                                        }
                                        {error && <p className="register-error error-pwd">{error}</p>}
                                    </div>
                                    <button type="submit" className="button-submit">S'enregistrer</button>
                                </form>
                            )
                        }
                    }
                </Formik>
            </div>

        </div>
    )
}

export default Register

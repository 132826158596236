import React, { useEffect, useState } from "react"
import { Equipe, Game, GameColor } from "../../store/services/gameService"
import Score, { GameScore } from "./Score"
import PlayerCard from "./PlayerCard"
import { Utilisateur } from "../../store/services/utilisateurService"

export interface EquipeProps {
    currentUserid: number,
    game: Game,
    equipe: Equipe,
    side: "top" | "bottom", // used to match css class
    clickRemove: (userId: number, username: string) => void,
    updateScoreBleu: (score: number) => void,
    updateScoreRouge: (score: number) => void,
    score: GameScore,
}

const EquipeCard: React.FunctionComponent<EquipeProps> =
    ({ currentUserid, equipe, game, side,  clickRemove, updateScoreBleu, updateScoreRouge, score }) => {

        // const bleu = "$darkBlue";
        // const rouge = "$red";

        const defaultColor = side === "top" ? "bleu" : "rouge"

        const [color, setColor] = useState<GameColor>(defaultColor)

        useEffect(() => {
            if (equipe?.couleur) {
                setColor(equipe.couleur)
            } else {
                setColor(defaultColor)
            }
        }, [equipe, defaultColor])

        /*
     * Can manage (before score validation
     * - own player
     * - every player if game leader before team validation
     */
        const canManage = (targetUserId: number) => {
            return !game?.score_confirmer &&
                (currentUserid === targetUserId || !game?.valide && currentUserid === game?.game_leader?.id)
        }

        const getPlayerCard = (user: Utilisateur, side: "left" | "right", color: GameColor) => {
            return (
                <PlayerCard
                    user={user}
                    isGameLeader={user?.id === game?.game_leader?.id}
                    canManage={canManage(user?.id)}
                    side={side}
                    color={color}
                    gameHistory={game?.game_histories?.find(history => history.user.id === user?.id)}
                    clickRemove={clickRemove}
                    score={score} />
            )
        }

        return (
            <>
                <div className={`equipe-${side} equipe-${color}`}>
                    {
                        getPlayerCard(equipe?.users[0], "left", color)
                    }
                    {
                        getPlayerCard(equipe?.users[1], "right", color)
                    }
                </div>
                {
                    game?.valide && 
                    <div className={`score score-${side}`}>
                        <Score
                            score={color === "bleu" ? score.score_bleu : score.score_rouge}
                            canChange={!game?.score_valide && currentUserid === game?.game_leader?.id}
                            setScore={color === "bleu" ? updateScoreBleu : updateScoreRouge} />
                    </div>
                
                }
            </>
        )
    }

export default EquipeCard

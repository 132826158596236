import React from "react"

export interface HeaderProps {
    headerHeight: number,
  }

const Header: React.FunctionComponent<HeaderProps> = ({ headerHeight }) => {
    return (
        <header className={`BabyfootArenas-header BabyfootArenas-header-${headerHeight}`}>
            <img src={"logo/LOGO_LOGO 64X64.svg"} className="BabyfootArenas-logo" alt="logo" />
            <div className='slogan'>LE JEU CONTINUE</div>
        </header>
    )
}

export default Header

import React from "react"
import { Link } from "react-router-dom"

const Footer: React.FunctionComponent = () => {
    return (
        <footer className="footer">
        
            <a className="link" href="https://www.distorsion.io/" target="_blank" rel="noopener noreferrer">
                ©Distorsion
            </a>
        &nbsp; - &nbsp;
            <div>BABYFOOT-ARENAS</div>
        &nbsp; - &nbsp;
            <div>2024</div>
        &nbsp; - &nbsp;
            {/* <div>Tous droits reservés</div>
        &nbsp; - &nbsp; */}
            <div>
                <Link className="link" to={"/cgu"}>CGU</Link>
            </div>
        </footer>
    ) 
}

export default Footer

import React, { useEffect } from 'react';
import { utilisateurService } from '../store/services/utilisateurService';
import { Field, Formik } from 'formik';
import { object, ref, string } from 'yup';
import eyeOnIcon from '../img/icons/eye_on.svg';
import eyeOffIcon from '../img/icons/eye_off.svg';
import { useNavigate } from 'react-router-dom';
import { login } from '../store/slices/utilisateurSlice';
import { useStoreDispatch } from '../store/Hook';
import Header from '../components/Header';

interface ResetPasswordForm {
    password: string,
    passwordConfirmation: string,
}

const ResetPassword: React.FunctionComponent = () => {

    const schema = object().shape({
        password: string()
            .required("Requis")
            .min(6, "Le mot de passe doit faire au moins 6 caractères"),
        passwordConfirmation: string()
            .required("Requis")
            .oneOf([ref("password"), null], "Les mots de passes doivent être identiques"),
    })


    const [error, setError] = React.useState(undefined)
    const [code, setCode] = React.useState("")
    const [showPassword, setShowPassword] = React.useState(false)
    const [showPasswordConfirmation, setShowPasswordConfirmation] = React.useState(false)

    const [resetPassword] =
        utilisateurService.useResetPasswordMutation()

    const dispatch = useStoreDispatch()
    const navigate = useNavigate()

    const handleSubmit = async (value: ResetPasswordForm) => {
        console.log(value)
        resetPassword({
            code,
            password: value.password,
            passwordConfirmation: value.passwordConfirmation
        })
            .unwrap()
            .then(response => {
                dispatch(login(response))
                navigate("/carte")
            })
            .catch(e => {
                setError("Une erreur s'est produite, veuillez réessayer ultérieurement." + e)
            })
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const codeParams = queryParameters.get("code")
        setCode(codeParams)
        if (codeParams === undefined || codeParams === null) {
            navigate('/forgot-password')
        }
    }, [])

    const handlePassword = () => {
        if (showPassword) {
            setShowPassword(false)
        } else {
            setShowPassword(true)
        }
    }

    const handlePasswordConfirmation = () => {
        if (showPasswordConfirmation) {
            setShowPasswordConfirmation(false)
        } else {
            setShowPasswordConfirmation(true)
        }
    }

    return (
        <div className="BabyfootArenas-body">

            <Header headerHeight={10} />

            <div className="home-gape"></div>

            <div className="home-link">
                <Formik
                    initialValues={{ password: "", passwordConfirmation: "" }}
                    validationSchema={schema}
                    onSubmit={handleSubmit}>
                    {
                        (props) => {
                            return (
                                <form onSubmit={props.handleSubmit} className='player-selection-form'>
                                    <div className="form-content">
                                        <label className="label-form" htmlFor="password">Mot de passe</label>
                                        <Field
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            className="form-control rounded-0" />
                                        <div className="button-showPassword" onClick={handlePassword}>
                                            {!showPassword && <img src={eyeOnIcon} alt="show" className="eye"></img> }
                                            {showPassword && <img src={eyeOffIcon} alt="show" className="eye"></img> }
                                        </div>
                                        {
                                            props.errors.password
                                            &&
                                            <p className="register-error error-pwd">
                                                {props.errors.password}
                                                {" "}
                                            </p>
                                        }
                                    </div>

                                    <div className="form-content">
                                        <label className="label-form" htmlFor="password_confirmation">
                                            Confirmation du mot de passe
                                        </label>
                                        <Field
                                            type={showPasswordConfirmation ? "text" : "password"}
                                            name="passwordConfirmation"
                                            className="form-control rounded-0" />
                                        <div className="button-showPassword" onClick={handlePasswordConfirmation}>
                                            {
                                                !showPasswordConfirmation &&
                                                <img src={eyeOnIcon} alt="show" className="eye"></img>
                                            }
                                            {
                                                showPasswordConfirmation &&
                                                <img src={eyeOffIcon} alt="show" className="eye"></img>
                                            }
                                        </div>
                                        {
                                            props.errors.passwordConfirmation &&
                                            <p className="register-error error-pwd">
                                                {props.errors.passwordConfirmation}
                                            </p>
                                        }
                                        {error && <p className="register-error error-pwd">{error}</p>}
                                    </div>
                                    <button className='button-submit' type='submit'>
                                        Changer le mot de passe
                                    </button>
                                </form>
                            )
                        }
                    }
                </Formik>
            </div>
        </div>
    )
}

export default ResetPassword

import { api } from "./api"

export type Ranks = [{
  arena_id: number,
  arena: string,
  rank: number,
}]

export type ArenaRanks = {
  arenaName: number,
  points: number,
  username: string,
  user_id: number,
  rank: number,
  isMe: boolean,
}

export const rankingService = api.injectEndpoints({
    endpoints: (builder) => ({
        getMonthlyRanking: builder.mutation<Ranks, number>({
            query: (id) => ({
                url: `api/classements/monthly/${id}`,
                method: "GET",
                params: { populate: "image" },
            }),
        }),
        getArenaRanking: builder.mutation<ArenaRanks[], string>({
            query: (uid) => ({
                url: `api/classements/${uid}`,
                method: "GET",
                params: { populate: "image" },
            }),
        }),
    }),
})

import { api } from "./api"

interface QueryResult<type> {
    data: type
}

interface AvatarImg {
    data: {
        attributes: {
            url: string,
        }
    }
}

interface Avatar {
    id: number,
    attributes: {
        partie: "tete",
        code: string,
        image: AvatarImg
    }
}

export const avatarService = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllAvatars: builder.query<QueryResult<Avatar[]>, null>({
            query: () => ({
                url: "api/avatars?populate=image",
                method: "GET",
                params: { populate: "image" },
            }),
        }),
        getAvatar: builder.mutation<QueryResult<Avatar>, number>({
            query: (id) => ({
                url: `api/avatars/${id}?populate=image`,
                method: "GET",
                params: { populate: "image" },
            }),
        }),
    }),
})

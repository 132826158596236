import { useLocation } from "react-router-dom"
import React from "react"

export function usePreviousQuery(): string | null {
    const { search } = useLocation()

    const previous = React.useMemo(() => new URLSearchParams(search).get("previous"), [search])

    return previous !== null ? `?previous=${previous}` : ""
}


export function usePreviousQueryValue(): string | null {
    const { search } = useLocation()

    const previous = React.useMemo(() => new URLSearchParams(search).get("previous"), [search])

    return previous !== null ? previous : ""
}

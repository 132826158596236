import React from "react"

export interface StatisticCardProps {
  label: string,
  stat: number,
}

const StatisticCard: React.FunctionComponent<StatisticCardProps> = ({ label, stat }) => {

    return (
        <div className="statistic-card">
            <span className="statistic-card-stat">{stat ?? 0}</span>
            <span className="statistic-card-label">{label}</span>
        </div>
    )
}

export default StatisticCard

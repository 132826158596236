import React, { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import Header from "../components/Header"
import { isLogged } from "../store/slices/utilisateurSlice"
import { useStoreSelector } from "../store/Hook"
import { usePreviousQuery } from "../hooks/usePreviousQuery"

const Home: React.FunctionComponent = () => {

    const previous: string = usePreviousQuery()

    const isConnected = useStoreSelector(isLogged)
    const navigate = useNavigate()

    useEffect(() => {
        if (isConnected) {
            navigate("/game") // /game  ?
        }
    }, [isConnected, navigate])

    return (
        <div className="BabyfootArenas-body">

            <Header headerHeight={30} />

            <div className="home-gape"></div>

            <div className="home-link">
                <Link to={`/login${previous}`} className="btn-play">
                    SE CONNECTER
                </Link>
                <Link to={`/register${previous}`} className="btn-play">
                    S'INSCRIRE
                </Link>
                <a
                    className="link-home"
                    href="https://www.babyfootarenas.com/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Qu'est ce que c'est encore ce machin ?
                </a>
            </div>
        </div>
    )
}
export default Home

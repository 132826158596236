import React from "react"
import { ReactSVG } from "react-svg"
import { css } from "glamor"
import { baseUrl } from "../../store/services/api"

export interface SpriteProps {
    url: string,
    options: {
        tShirtColor?: string,
        etat?: string
    }
}

const Sprite: React.FunctionComponent<SpriteProps> = ({ url, options }: SpriteProps) => {

    const styleRaw = {}

    if (options.tShirtColor !== undefined) {
        styleRaw[" svg #tshirt path"] = {
            fill: options.tShirtColor,
        }
    }

    const hideFace = (etat: "win" | "lose" | "regular") => {
        styleRaw[` svg #face-${etat} path`] = {
            visibility: "hidden",
        }
        styleRaw[` svg #face-${etat} rect`] = {
            visibility: "hidden",
        }
        styleRaw[` svg #face-${etat} ellipse`] = {
            visibility: "hidden",
        }
        styleRaw[` svg #face-${etat} polygon`] = {
            visibility: "hidden",
        }
        styleRaw[` svg #face-${etat} circle`] = {
            visibility: "hidden",
        }
    }

    if (options.etat === "lose") {
        hideFace("win")
        hideFace("regular")
    } else if (options.etat === "win") {
        hideFace("lose")
        hideFace("regular")
    } else {
        hideFace("win")
        hideFace("lose")
    }

    const styles = css(styleRaw)

    return (
        <>
            {url !== undefined && <ReactSVG src={`${baseUrl}${url}`} {...styles} className='avatar-sprite' />}
        </>
    )
}

export default Sprite

import React, { useEffect, useState } from "react"
import { avatarService } from "../../store/services/avatarService"
import Sprite from "./Sprite"

interface AvatarProps {
	avatarId: number | null
    setAvatar: (avatarId) => void
}

const Avatar: React.FunctionComponent<AvatarProps> = ({ avatarId, setAvatar } : AvatarProps) => {

    const { data: avatars } = avatarService.useGetAllAvatarsQuery(null)

    // Setting up the initial states using react hook 'useState'
    const [indiceSprite, setIndiceSprites] = useState(avatarId ? avatarId : 0)
    const [sprites, setSprites] = useState([])

    useEffect(() => {
        if (!avatars) {
            return
        }
        setSprites(avatars.data)
        if (avatarId) {
            setIndiceSprites(avatars.data.findIndex(sprite => sprite.id === avatarId))
        } else {
            setAvatar(avatars.data[0].id)
        }
    }, [avatars, avatarId, setAvatar])

    function upIndices() {

        const nextIndice = indiceSprite + 1
        setIndiceSprites(nextIndice)
        
        // TODO : récupérer correctement l'avatarId et le store
        if (sprites.length > 0) {
            setAvatar(sprites[nextIndice % sprites.length].id)
        }
    }

    function downIndices() {

        let nextIndice = indiceSprite;
        if (nextIndice - 1 >= 0){
            nextIndice = indiceSprite - 1;
        } else {
            nextIndice = sprites.length - 1;
        }
        setIndiceSprites(nextIndice)

        // TODO : récupérer correctement l'avatarId et le store
        if (sprites.length > 0) {
            setAvatar(sprites[nextIndice % sprites.length].id)
        } 
    }

    return (
        <div className="home">

            {
                <button id="gen-down" className="button-avatar button-arrow" onClick={
                    () => {
                        downIndices() 
                    }
                }>
                    {"<"}
                </button> 
            }

            <div className="avatar">
                {
                    sprites.length > 0 &&
                    <Sprite
                        url={sprites[indiceSprite % sprites.length].attributes.image.data.attributes.url}
                        options={{ tShirtColor: "#771610", etat: "regular" }} />
                }
            </div>    

            {
                <button id="gen-up" className="button-avatar button-arrow" onClick={
                    () => {
                        upIndices() 
                    }
                }>
                    {">"}
                </button>
            }
            
        </div>
    )
}

export default Avatar

import { api } from "./api"
import { ArenaBase } from "./arenaService"
import { Utilisateur } from "./utilisateurService"

export type GameColor = "bleu" | "rouge"

export interface Equipe {
    couleur: GameColor,
    uuid: string,
    users: Utilisateur[],
}

export interface Terrain {
    uuid: string,
    arena: ArenaBase
}

export interface GameHistory {
    elo: number,
    elo_diff: number,
    user: Utilisateur
}

export interface Game {
    equipes: Equipe[],
    game_leader: Utilisateur,
    terrain: Terrain,
    valide: boolean,
    score_valide: boolean,
    score_confirmer: boolean,
    uuid: string,
    score_bleu: number | null,
    score_rouge: number | null,
    game_histories: GameHistory[],
    createdAt: string,
    is_finish: boolean,
}

export const gameService = api.injectEndpoints({
    endpoints: (builder) => ({
        joinGame: builder.mutation<Game, {
            terrainUid: string,
            color: string,
        }>({
            query: (params) => ({
                url: `api/matches/join/${params.terrainUid}/${params.color}`,
                method: "POST",
            }),
        }),
        validTeam: builder.mutation<Game, {
            gameUid: string,
        }>({
            query: (params) => ({
                url: `api/matches/${params.gameUid}/team/valid`,
                method: "PUT",
            }),
        }),
        score: builder.mutation<Game, {
            gameUid: string,
            score_bleu: number,
            score_rouge: number,
        }>({
            query: (params) => ({
                url: `api/matches/${params.gameUid}/score`,
                method: "PUT",
                body: {
                    score_bleu: params.score_bleu,
                    score_rouge: params.score_rouge,
                },
            }),
        }),
        confirmeScore: builder.mutation<Game, {
            gameUid: string,
            confirmation: boolean,
        }>({
            query: (params) => ({
                url: `api/matches/${params.gameUid}/score/valid`,
                method: "PUT",
                body: {
                    confirmation: params.confirmation,
                },
            }),
        }),
        quitGame: builder.mutation<void, {
            gameUid: string,
            userId: number,
        }>({
            query: (params) => ({
                url: `api/matches/${params.gameUid}/quit`,
                method: "PUT",
                body: {
                    userId: params.userId,
                },
            }),
        }),
    }),
})

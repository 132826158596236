import React, { useEffect } from "react"
import Modal from "react-modal"
import { Arena } from "../../store/services/arenaService"
import ArenaTooltip, { ArenaTooltipStyle } from "./modal_content/ArenaTooltip"
import ArenaDetails, { ArenaDetailsStyle } from "./modal_content/ArenaDetails"
import ArenaRanking, { ArenaRankingStyle } from "./modal_content/ArenaRanking"

export type MapModalSwitchAction = (event: React.MouseEvent | React.KeyboardEvent) => void

export const enum MapModalEnum {
  tooltip,
  details,
  ranking,
}

export interface MapArenaModalProps {
  open: boolean,
  onClose: () => void,
  arena?: Arena,
}


// Ajoute des contraintes de type plus forte que Modal.Styles pour s'assurer que les mêmes propriété soit set dans
// toute les modals de la map sinon on risque d'avoir des surprises du à l'apparition/disparition de règles de style
export type MapModalStyles = {
  content?: Pick<React.CSSProperties,
    "inset" |
    "height" |
    "backgroundColor" |
    "color" |
    "opacity" |
    "overflow" |
    "WebkitOverflowScrolling" |
    "borderRadius" |
    "outline" |
    "padding" |
    "display" |
    "flexDirection" |
    "justifyContent" |
    "alignItems" |
    "textAlign"> | undefined;
  overlay?: Pick<React.CSSProperties,
    "position" |
    "top" |
    "left" |
    "right" |
    "minHeight" |
    "maxHeight" |
    "margin" |
    "backgroundColor"
  > | undefined;
}

const MapModal: React.FunctionComponent<MapArenaModalProps> = ({ open, onClose, arena }) => {

    const [modalStyle, setModalStyle] = React.useState<MapModalStyles>(ArenaTooltipStyle)
    const [content, setContent] = React.useState<React.ReactNode>(<></>)
    const [modalType, setModalType] = React.useState<MapModalEnum>(MapModalEnum.tooltip)

    const resetModal = () => {
        setModalType(MapModalEnum.tooltip)
        setModalStyle(ArenaTooltipStyle)
        setContent(<></>)
    }

    const closeModal = () => {
        resetModal()
        onClose()
    }

    const switchToTooltip: MapModalSwitchAction = (event: React.MouseEvent | React.KeyboardEvent) => {
        event.stopPropagation()
        setModalType(MapModalEnum.tooltip)
    }

    const switchToDetails: MapModalSwitchAction = (event: React.MouseEvent | React.KeyboardEvent) => {
        event.stopPropagation()
        setModalType(MapModalEnum.details)
    }

    const switchToRanking: MapModalSwitchAction = (event: React.MouseEvent | React.KeyboardEvent) => {
        event.stopPropagation()
        setModalType(MapModalEnum.ranking)
    }

    useEffect(() => {
        console.log("open", open)
        if (!open) {
            console.log("(resetModal)")
            resetModal()
        }
    }, [open])

    useEffect(() => {
        if (!arena) {
            resetModal()
            return
        }

        switch (modalType) {
        case MapModalEnum.tooltip:
            setModalStyle(ArenaTooltipStyle)
            setContent(
                <ArenaTooltip arena={arena} onSwitchDetails={switchToDetails} onSwitchRanking={switchToRanking} />
            )
            break
        case MapModalEnum.details:
            setModalStyle(ArenaDetailsStyle)
            setContent(<ArenaDetails arena={arena} onSwitchBack={switchToTooltip} />)
            break
        case MapModalEnum.ranking:
            setModalStyle(ArenaRankingStyle)
            setContent(<ArenaRanking arena={
                {
                    id: arena.id.toString(),
                    nom: arena.attributes.nom,
                }
            } onSwitchBack={switchToTooltip} />)
            break
        default:
            resetModal()
            break
        }
    }, [arena, modalType])

    return (
        <Modal
            isOpen={open}
            onRequestClose={closeModal}
            contentLabel='Arena'
            style={modalStyle}>
            {content}
        </Modal>
    )
}

export default MapModal

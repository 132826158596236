import React, { useEffect, useMemo, useRef, useState } from "react"
import MenuNavigation from "../components/MenuNavigation"
import { Arena, arenaService } from "../store/services/arenaService"

import Map from "ol/Map.js"
import { addArenaLayer, getMap } from "../map/map"
import MapModal from "../components/modal/MapModal"
import { SelectEvent } from "ol/interaction/Select"

function populateAlerte() {
    alert("Tu souhaites proposer une nouvelle arène ? Génial !\n\n"
    + "Cependant nos développeurs passent trop de temps à jouer au babyfoot !\n"
    + "Cette fonctionnalité n'est pas donc encore finalisée mais tu peux faire une demande via un Google Form.\n\n"
    + "Tu vas être redirigé vers ce formulaire... merci à toi :-)"
    )
}

const Carte: React.FunctionComponent = () => {

    const mapTargetElement = useRef<HTMLDivElement>(null)
    const [map, setMap] = useState<Map | undefined>()
    const [arena, setArena] = useState<Arena | undefined>()

    // Modals
    const [arenaPopinOpen, setArenaPopinOpen] = useState<boolean>(false)

    const { data: arenas } = arenaService.useGetArenasMapQuery(null)

    useEffect(() => {
        const onSelect = (e: SelectEvent) => {
            const feature = e.selected[0]
            if (feature) {
                const arena = feature.get("arena")
                setArenaPopinOpen(true)
                setArena(arena)
            } else {
                setArenaPopinOpen(false)
                setArena(undefined)
                return
            }
        }

        const map = getMap(mapTargetElement.current || "", onSelect)

        setMap(map)
        return () => map.setTarget("")
    }, [])

    useMemo(() => {
        if (!arenas || !map) {
            return
        }
        addArenaLayer(map, arenas.data)
    }, [arenas, map])

    return (
        <div className='BabyfootArenas-body'>
            {/*
        Div contenant la map
      */}
            <div
                ref={mapTargetElement}
                className='map'>
                <button className='map-recommand-button' onClick={() => populateAlerte()}>
                <a className="link-map" href="https://forms.gle/szqtzLJgLK5U67SY9" target="_blank" rel="noopener noreferrer">+ AJOUTER UNE ARÈNE</a>
                </button>
            </div>
            <MapModal open={arenaPopinOpen} onClose={() => setArenaPopinOpen(false)} arena={arena} ></MapModal>
            <MenuNavigation />
        </div>
    )
}

export default Carte

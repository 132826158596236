import { api } from "./api"
import { stringify } from "qs"

export interface QueryResult<type> {
  data: type
}

export interface ArenaBase {
  id: string,
  nom: string,  
}

export interface Arena {
  id: number,
  attributes: {
    nom: string,
    active : boolean,
    visible : boolean,
    longitude: number,
    latitude: number,
    adresse?: {
      id: number,
      street: string,
      postal_code: number,
      city: string,
    },
    ouverture?: [{
      day: string,
      horaire: [{
        start: string,
        end: string,
      }]
    }]
  }
}

export const arenaService = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllArenas: builder.query<QueryResult<Arena[]>, null>({
            query: () => ({
                url: "api/arenas",
                method: "GET",
            }),
        }),
        // Pour l'instant c'est une duplication, on aura besoin d'une recherche géographique à un moment
        getArenasMap: builder.query<QueryResult<Arena[]>, null>({
            query: () => {
                const query = {
                    populate: ["adresse", "ouverture.horaire"],
                }
                return {
                    url: `api/arenas?${stringify(query)}`,
                    method: "GET",
                }
            },
        }),
    }),
})

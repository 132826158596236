import { api } from "./api"
import { Credentials } from "../../types/Credentials"

export interface Login {
    identifier: string,
    password: string,
}

export interface Register {
    username: string,
    email: string,
    password: string,
}

interface Email {
    email: string,
}

interface ResetPassword {
    code: string,
    password: string,
    passwordConfirmation: string,
}

export interface Image {
    url: string,
}

export interface Avatar {
    id: number,
    code?: string,
    image?: Image,
}

export interface Utilisateur {
    id: number,
    username: string,
    email: string,
    password?: string,
    elo: number,
    avatar: Avatar,
}

export interface BasicResponse {
    ok: boolean
}

export interface Connection extends Credentials {
    user: Utilisateur,
}

export const utilisateurService = api.injectEndpoints({
    endpoints: (builder) => ({
        createUtilisateur: builder.mutation<Connection, Register>({
            query: (utilisateur) => ({
                url: "api/auth/local/register",
                method: "POST",
                body: utilisateur,
            }),
        }),
        loginUtilisateur: builder.mutation<Connection, Login>({
            query: (login) => ({
                url: "api/auth/local",
                method: "POST",
                body: login,
            }),
        }),
        forgotPassword: builder.mutation<BasicResponse, Email>({
            query: (email) => ({
                url: "api/auth/forgot-password",
                method: "POST",
                body: email,
            }),
        }),
        resetPassword: builder.mutation<Connection, ResetPassword>({
            query: (email) => ({
                url: "api/auth/reset-password",
                method: "POST",
                body: email,
            }),
        }),
        updateUtilisateur: builder.mutation<Utilisateur, Partial<Utilisateur>>({
            query: (user) => ({
                url: `api/users/${user.id}`,
                method: "PUT",
                params: { populate: ["avatar"] },
                body: user,
            }),
        }),
        getUtilisateurAvatar: builder.mutation<Utilisateur, number>({
            query: (userId) => ({
                url: `api/users/${userId}`,
                method: "GET",
                params: {
                    fields: ["id"],
                    populate: ["avatar.image"],
                },
            }),
        }),
        getUtilisateur: builder.mutation<Utilisateur, void>({
            query: () => ({
                url: "api/users/me",
                method: "GET",
                params: {
                    populate: ["avatar.image"],
                },
            }),
        }),
    }),
})

import React from "react"
import { Link } from "react-router-dom"
import { usePreviousQuery } from "../hooks/usePreviousQuery"
import ReactMarkdown from "react-markdown";

const Cgu: React.FunctionComponent = () => {

    const previous: string = usePreviousQuery()
    return (
        <div className="BabyfootArenas-body">

            <div className="home-gape-20"></div>

            <div className="home-link">

            <ReactMarkdown className="cgu">{`
# Conditions générales d'utilisation de Babyfoot Arenas

Les présentes conditions générales régissent l'utilisation de l'application web Babyfoot Arenas. En accédant à cette application, vous acceptez pleinement et sans réserve ces conditions. Veuillez les lire attentivement avant d'utiliser notre service.

## 1. Acceptation des Conditions d'Utilisation

- En accédant à notre application, vous reconnaissez avoir lu, compris et accepté ces conditions. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser l'application.

## 2. Utilisation de l'Application

- L'application est destinée à un usage personnel uniquement. Toute utilisation à des fins commerciales est strictement interdite.
- Vous devez vous inscrire pour utiliser l'application. Vous êtes responsable de maintenir la confidentialité de votre compte et de vos informations d'identification.

## 3. Responsabilités de l'Utilisateur

- Vous êtes responsable de l'exactitude des données que vous saisissez dans l'application, notamment de vosz informations de connexion et des scores de vos parties.
- Vous vous engagez à ne pas utiliser l'application de manière abusive, à ne pas perturber le fonctionnement normal de celle-ci, et à respecter les autres utilisateurs.

## 4. Propriété Intellectuelle

- L'application et son contenu (à l'exception des données saisies par les utilisateurs) sont protégés par des droits de propriété intellectuelle.
- Les utilisateurs bénéficient d'une licence limitée pour utiliser l'application conformément à ces conditions.

## 5. Politique de confidentialité

**Collecte et Utilisation des Données :**
En utilisant cette application, vous consentez à la collecte de certaines données personnelles conformément à notre Politique de Confidentialité. Cela peut inclure, mais sans s'y limiter, les informations de compte, les lieux, scores, dates et heures des parties de baby-foot, et toute autre donnée saisie ou générée lors de l'utilisation de l'application.

**Finalités de Collecte :**
Les données collectées peuvent être utilisées pour améliorer nos services, personnaliser l'expérience de l'utilisateur, analyser les tendances et assurer le bon fonctionnement de l'application.

**Partage des Données :**
Nous pouvons partager des données dans les limites décrites dans notre Politique de Confidentialité, notamment avec des fournisseurs de services tiers qui nous aident à fournir et améliorer notre application.

**Protection des Données :**
Nous mettons en place des mesures de sécurité raisonnables pour protéger vos données contre la perte, l'accès non autorisé, la divulgation, l'altération ou la destruction.

**Cookies et Technologies Similaires :**

- L'application peut utiliser des cookies ou d'autres technologies similaires pour améliorer l'expérience de l'utilisateur. Un cookie est un petit fichier texte placé sur votre appareil lorsque vous visitez un site ou utilisez une application. Il permet à l'application de se souvenir de vos actions et préférences (telles que la connexion, la langue, la taille de police et d'autres préférences d'affichage) sur une période donnée, vous évitant ainsi de les saisir à chaque visite ou utilisation.
- Types de Cookies Utilisés :
  - Cookies Essentiels : Ces cookies sont indispensables au bon fonctionnement de l'application. Ils vous permettent de naviguer et d'utiliser ses fonctionnalités de base. Sans ces cookies, certaines fonctionnalités ne pourraient pas être fournies.
  - Cookies de Performance : Ces cookies collectent des informations sur la manière dont vous utilisez l'application, par exemple, les pages que vous visitez le plus souvent. Ils nous aident à améliorer le fonctionnement de l'application.
  - Cookies de Fonctionnalité : Ces cookies permettent à l'application de se souvenir des choix que vous faites (comme votre nom d'utilisateur, votre langue ou votre région) et fournissent des fonctionnalités améliorées et plus personnelles.
- Gestion des Cookies : Vous pouvez gérer et/ou supprimer les cookies selon vos préférences. Vous pouvez les effacer de votre appareil et définir la plupart des navigateurs pour bloquer leur utilisation. Cependant, veuillez noter que la désactivation des cookies peut affecter le bon fonctionnement de certaines fonctionnalités de l'application.
- Consentement : En utilisant notre application, vous consentez à l'utilisation des cookies conformément à cette Politique de Cookies.
- Modification de la Politique de Cookies : Nous nous réservons le droit de mettre à jour ou de modifier cette Politique de Cookies. Toute modification sera publiée sur l'application et la date d'entrée en vigueur sera modifiée en conséquence.

**Accord et Consentement :**
En utilisant cette application, vous consentez à la collecte, au traitement et à l'utilisation de vos données conformément à notre Politique de Confidentialité.

**Changements dans la Politique de Confidentialité :**
Nous nous réservons le droit de modifier notre Politique de Confidentialité. Toute modification sera publiée sur l'application.

## 6. Sécurité

- Nous mettons en œuvre des mesures de sécurité raisonnables pour protéger l'application et les données des utilisateurs. Cependant, aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée.

## 7. Modifications et Résiliation

- Nous nous réservons le droit de modifier ces conditions à tout moment. Toute modification prendra effet dès sa publication sur l'application.
- Vous pouvez résilier votre compte à tout moment en suivant les procédures indiquées dans l'application.

## 8. Limitation de Responsabilité

- Dans les limites autorisées par la loi, nous ne serons pas responsables des dommages directs, indirects, accessoires, spéciaux ou consécutifs résultant de l'utilisation ou de l'incapacité à utiliser l'application.

En utilisant cette application, vous acceptez et consentez à ces conditions. Si vous avez des questions ou des préoccupations concernant ces conditions, veuillez nous contacter à [contact@distorsion.io](mailto:contact@distorsion.io).
`}
</ReactMarkdown>
                
                <Link to={`/${previous}`} className="btn-play">
                    RETOUR
                </Link>

            </div>
        </div>
    )
}
export default Cgu

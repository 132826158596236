import React, { useEffect, useState } from "react"
import removeIcon from "./../../img/icons/remove_icon.svg"
import fantome from "../../img/avatar/fantome/AVATARS-FANTOME_CORPS_BLANC_ROUGE.svg"
import { GameColor, GameHistory } from "../../store/services/gameService"
import Sprite from "../Avatar/Sprite"
import { GameScore } from "./Score"
import { Utilisateur, utilisateurService } from "../../store/services/utilisateurService"

export interface PlayerGameProps {
    user?: Utilisateur,
    canManage: boolean,
    isGameLeader: boolean,
    side: "left" | "right",
    color: GameColor,
    clickRemove: (userId: number, username: string) => void,
    gameHistory: GameHistory,
    score: GameScore,
}

const PlayerCard: React.FunctionComponent<PlayerGameProps> =
    ({ user, canManage, isGameLeader, side, color, clickRemove, gameHistory, score }) => {

        const [avatarUrl, setAvatarUrl] = useState<string>(undefined)
        const [stateJoueur, setStateJoueur] = useState("")

        const [getAvatar] = utilisateurService.useGetUtilisateurAvatarMutation()

        useEffect(() => {
            if (!user) {
                return
            }
            getAvatar(user.id)
                .unwrap()
                .then((avatar) => {
                    setAvatarUrl(avatar.avatar.image.url)
                })
        }, [user, getAvatar])

        useEffect(() => {
            if (!score) {
                return
            }

            // TODO : à remonter au niveau de l'équipe => faire 1 fois
            //  le useEffect() dans l'équipe au lieu d'1 fois par joueur (moins de calculs)
            if (color === "bleu" && score.score_bleu > score.score_rouge) {
                setStateJoueur("win")
            } else if (color === "bleu" && score.score_bleu < score.score_rouge) {
                setStateJoueur("lose")
            } else if (color === "rouge" && score.score_rouge > score.score_bleu) {
                setStateJoueur("win")
            } else if (color === "rouge" && score.score_rouge < score.score_bleu) {
                setStateJoueur("lose")
            } else {
                setStateJoueur("regular")
            }

        }, [score, color])


        const getPlaceholder = () => {
            return (
                <div className={`player ${side}`}>
                    <img src={fantome} alt="ombre-player-vide.middle-place"></img>
                </div>
            )
        }

        const getPlayer = () => {
            return (
                <div className={`player ${side}`}>
                    <div className="player-avatar player-avatar-highlight"> {/* TO DO : refacto front Game display before */}
                        <Sprite
                            url={avatarUrl}
                            options={ { tShirtColor: color === "bleu" ? "#174061" : "#771610", etat: stateJoueur }} />
                        {/* impossible d'utiliser $darkBlue et $red en l'état */}
                    </div>
                    <div className="playerInfo"> 
                        <span>{user.username}</span>
                        {
                            isGameLeader && 
                            <>
                                <br></br>
                                <span className="player-capitaine">(capitaine)</span>
                            </>
                    
                        }
                        <span></span>
                        <br />
                        {
                            gameHistory && 
                            <>
                                {/* <span>{gameHistory.elo} ({gameHistory.elo_diff})</span> */}
                                <span>Niveau 1</span>
                            </>
                        
                        }
                    </div>
                    {
                        canManage && 
                        <button className="picto-remove">
                            <img src={removeIcon} alt="remove" className="picto-remove-img"
                                onClick={
                                    () => {
                                        clickRemove(user.id, user.username)
                                    }
                                }>
                            </img>
                        </button>
                    
                    }
                </div>
            )
        }

        return (
            <>
                {
                    !user ? getPlaceholder() : getPlayer()
                }
            </>
        )
    }

export default PlayerCard

import React from "react"
import moment from "moment"
import { Arena } from "../../../store/services/arenaService"
import { MapModalStyles, MapModalSwitchAction } from "../MapModal"

export interface MapArenaModalProps {
  arena?: Arena,
  onSwitchBack: MapModalSwitchAction,
}

export const ArenaDetailsStyle: MapModalStyles = {
    overlay: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        minHeight: "30%",
        maxHeight: "70%",
        margin: "20px",
        backgroundColor: "none",
    },
    content: {
        inset: "0",
        // height: "100%",
        opacity: "0.95",
        backgroundColor: "#fff",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        borderRadius: "5px",
        outline: "none",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },
}

const ArenaDetails: React.FunctionComponent<MapArenaModalProps> = ({ arena, onSwitchBack }) => {

    return (
        <>
            <h2 className='map-arena-modal-title'>{arena?.attributes.nom}</h2>
            <div className='map-arena-modal-section'>
                <p>{arena?.attributes.adresse.street}</p>
                <p>
                    {arena?.attributes.adresse.postal_code} 
                    {" "}
                    {arena?.attributes.adresse.city}
                </p>
            </div>
            <div className='map-arena-modal-section'>
                {
                    arena?.attributes.ouverture && arena?.attributes.ouverture.map(ouverture =>
                        <div className='map-arena-modal-opening' key={`opening-${ouverture.day}`}>
                            <p>{ouverture.day}</p>
                            <p>
                                {
                                    ouverture.horaire
                                    // on map les horaires dans une span 'ouverture - fermeture'
                                        .map(horaire =>
                                            <span>
                                                {moment(horaire.start, "HH:mm:ss").format("HH:mm")}
                                                {" "}
                                                -
                                                {" "}
                                                {moment(horaire.end, "HH:mm:ss").format("HH:mm")}
                                            </span>
                                        )
                                    // Il peut y avoir plusieurs créneaux horaires sur une journée
                                    // On transforme notre tableau de span en 1 seul élément JSX avec
                                    // des espaces entre les spans
                                        .reduce((planning: JSX.Element, horaire) =>
                                            <>
                                                {planning}
                                                {" "}
                                                {horaire}
                                            </>
                                        )
                                }
                            </p>
                        </div>
                    )
                }
            </div>
            <button className='map-arena-modal-close-button' onClick={onSwitchBack}>ok</button>
        </>
    )
}

export default ArenaDetails

import { Navigate, RouteProps } from "react-router-dom"
import { useStoreSelector } from "../../store/Hook"
import { isLogged } from "../../store/slices/utilisateurSlice"
import React from "react"

const PrivateRoute: React.FunctionComponent<RouteProps> = (props) => {
    const isConnected = useStoreSelector(isLogged)

    return isConnected ? 
        <>{props.children}</>
        : 
        <Navigate to={`/?previous=${window.location.pathname}`} replace />
}

export default PrivateRoute

import { api } from "./api"

export interface Stats {
  nb_game: number,
  nb_arena: number,
  nb_victory: number
}

export const statService = api.injectEndpoints({
    endpoints: (builder) => ({
        getStats: builder.mutation<Stats[], void>({
            query: () => ({
                url: "api/game-histories/user",
                method: "GET",
                params: { populate: "image" },
            }),
        }),
    }),
})

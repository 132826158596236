import React, { useEffect } from "react"
import Avatar from "../components/Avatar/Avatar"
import { useNavigate } from "react-router-dom"
import { getUser, setUser } from "../store/slices/utilisateurSlice"
import { useStoreDispatch, useStoreSelector } from "../store/Hook"
import Header from "../components/Header"
import { Field, Formik } from "formik"
import { object, string } from "yup"
import { usePreviousQueryValue } from "../hooks/usePreviousQuery"
import { utilisateurService } from "../store/services/utilisateurService"

interface PlayerSelectionForm {
    username: string,
}

const PlayerSelection: React.FunctionComponent = () => {

    const schema = object().shape({
        username: string()
            .required("Requis")
            .matches(/^\p{Letter}[\p{Letter}\p{Number}\p{Dash_Punctuation}\p{Connector_Punctuation}]+$/u, {  //original matcher
                message: "Le pseudo doit uniquement être composé de lettres, de chiffres et d'underscore.\nEnfin, le pseudo doit commencer par une lettre.",
            })
            .min(3, "Le pseudo doit comporter au moins 3 caractères.")
            .max(10, "Le pseudo ne doit pas comporter plus de 10 caractères."),
    })

    const previous: string = usePreviousQueryValue()

    const dispatch = useStoreDispatch()
    const user = useStoreSelector(getUser)

    const [avatar, setAvatar] = React.useState(user?.avatar?.id ?? null)
    const [canChangeUsername, setCanChangeUsername] = React.useState(false)
    const [username, setUsername] = React.useState<string>(undefined)
    const [error, setError] = React.useState(undefined)

    const [updateUtilisateur] = utilisateurService.useUpdateUtilisateurMutation()

    const navigate = useNavigate()

    useEffect(() => {
        setCanChangeUsername(user.username.toLowerCase() === user.email.toLowerCase())
    }, [user.email, user.username])

    useEffect(() => {
        if (!user) {
            return
        }
        setAvatar(user?.avatar?.id)
        setUsername(!canChangeUsername ? user.username : undefined)
    }, [canChangeUsername, user])

    function handleSubmit(value: PlayerSelectionForm) {

        const restAction = canChangeUsername ? updateUtilisateur({
            id: user.id,
            username: value.username,
            avatar: { id: avatar },
        }) : updateUtilisateur({ id: user.id, avatar: { id: avatar } })

        restAction
            .unwrap()
            .then(response => {
                setError(undefined)
                setUsername(username)
                dispatch(setUser(response))

                if (previous) {
                    navigate(previous)
                } else {
                    navigate("/carte")
                }
            })
            .catch(e => {

                console.log("catch", e)
                if (e.status === 400 && e?.data?.error.name === "ApplicationError") {
                    setError("Pseudo déjà utilisé")
                }
            })
    }

    return (
        <div className='BabyfootArenas-body'>

            <Header headerHeight={10} />

            <div className='home-gape-20' />

            <div className='home-link'>

                <Avatar avatarId={avatar} setAvatar={setAvatar} />

                <Formik
                    initialValues={{ username: (user.username.toLowerCase() === user.email.toLowerCase() ? undefined : user.username) ?? username }}
                    validationSchema={schema}
                    onSubmit={handleSubmit}>
                    {
                        (props) => {
                            return (
                                <form onSubmit={props.handleSubmit} className='player-selection-form'>
                                    <Field
                                        type='text'
                                        placeholder='Pseudo'
                                        name={'username'}
                                        readOnly={!canChangeUsername} />
                                    {props.errors.username && <p className='register-error'>{props.errors.username}</p>}

                                    {error && <p className='register-error'>{error}</p>}

                                    <button className='button-submit' type='submit'>ENREGISTRER</button>
                                </form>
                            )
                        }
                    }
                </Formik>
            </div>
        </div>
    )
}

export default PlayerSelection

import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

export interface MenuOpt {
    visible: boolean;
}

const getInitialState = (): MenuOpt => {
    return {
        visible: true,
    }
}

const initialState = getInitialState()
export const menuSlice = createSlice({
    name: "menuSlice",
    initialState,
    reducers: {
        hideMenu: (state) => {
            state.visible = false
        },
        showMenu: (state) => {
            state.visible = true
        },
    },
})

export const {
    hideMenu,
    showMenu,
} = menuSlice.actions

export const getMenuOpt = (state: RootState): MenuOpt => {
    return state.menuSlice
}

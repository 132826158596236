import React from "react"
import Sprite, { SpriteProps } from "../Avatar/Sprite"

interface  ArenaRankingAvatarCardProps extends SpriteProps {
  rank: number,
  order: string,
}

const ArenaRankingAvatarCard: React.FunctionComponent<ArenaRankingAvatarCardProps> =
    ({ url, options, rank, order }) => {
        return (
            <div className="arena-ranking-avatar" id={order}>

                {/* TODO : card à améliorer => rank pris en compte, affichage du pseudo...*/}

                {/* <div className="arena-ranking-avatar-card"> */}
                <div className={`arena-ranking-avatar-card-${rank + 1}`}>
                    {/* <div className="positionOnTShirt">{rank+1}</div> */}
                    { <Sprite url={url} options={options} /> }
                </div>
                {/* </div> */}
            </div>
        )
    }

export default ArenaRankingAvatarCard

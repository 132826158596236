import React, { ReactElement, useState } from "react"
import ArenaRankingCard from "../cards/ArenaRankingCard"
import RankingModal from "../modal/RankingModal"
import { ArenaBase } from "../../store/services/arenaService"

interface PresentationProps {
  ranks: [{
    arena: string,
    arena_id: number,
    rank: number,
  }]
}

const Presentation: React.FunctionComponent<PresentationProps> = ({ ranks }) => {

    const ranking: ReactElement[] = []

    const [arena, setArena] = useState<ArenaBase>()
    const [isOpen, setIsOpen] = React.useState(false)

    function openArenaRanking(rank) {
        return () => {      
            setArena({
                id: rank.arena_id,
                nom: rank.arena,
            })
            setIsOpen(true)
        }
    }

    function onClose() {
        setIsOpen(false)
    }

    if (ranks) {
        for (const rank of ranks) {
            ranking.push(<ArenaRankingCard arena={rank.arena} rank={rank.rank} onClick={openArenaRanking(rank)} />)
        }
    }

    return (
        <>
            <h5>Mes classements actuels</h5>
            { 
                ranking.length === 0 &&
                <div className="gameHistoProfil">
                    pas encore de
                    <br />
                    matchs joués...
                </div>
            }
            <div className="profil-ranking">
                { ranking }
            </div>
            {<RankingModal arena={arena} open={isOpen} onClose={onClose} />}

            <h5>Mes dernières parties</h5>    
            <div className="gameHistoProfil">à venir...</div>

        </>
    )
}

export default Presentation

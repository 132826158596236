import React from "react"
import { NavLink } from "react-router-dom"
import { MenuOpt } from "../store/slices/menuSlice"
import { useStoreSelector } from "../store/Hook"
import carte from "./../img/menu/PICTOGRAMMES_PICTO_MAP.svg"
import arenas from "./../img/menu/PICTOGRAMMES_PICTO_ARENAS.svg"
import profil from "./../img/menu/PICTOGRAMMES_PICTO_PROFIL.svg"

const MenuNavigation: React.FunctionComponent = () => {
    const menuOpt: MenuOpt = useStoreSelector(state => state.menuSlice)

    return menuOpt.visible ? 
        <div className='bottom-menu'>
            {/* <div className='bottom-menu-items'> */}
            <div className='item'>
                <NavLink
                    to='/carte'
                    className={
                        ({ isActive })  =>
                            isActive ? "btn-menu btn-play btn-active" : "btn-menu btn-play"
                    }>
                    <img src={carte} className='picto' alt='MAP'></img>
                    <div className="text-menu-navigation">CARTE</div>
                </NavLink>                    
            </div>
            <div className='item'>
                <NavLink
                    to='/game'
                    className={
                        ({ isActive }) =>
                            isActive ? "btn-menu btn-play btn-active" : "btn-menu btn-play"
                    }>
                    <img src={arenas} className='picto' alt='ARENE'></img>
                    <div className="text-menu-navigation">ARENA</div>
                </NavLink>
            </div>
            <div className='item'>
                <NavLink
                    to='/profil'
                    className={
                        ({ isActive }) =>
                            isActive ? "btn-menu btn-play btn-active" : "btn-menu btn-play"
                    }>
                    <img src={profil} className='picto' alt='PROFIL'></img>
                    <div className="text-menu-navigation">PROFIL</div>
                </NavLink>
            </div>
            {/* </div> */}
        </div>
        : <></>
}

export default MenuNavigation

import React, { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import MenuNavigation from "../components/MenuNavigation"
import { useStoreDispatch, useStoreSelector } from "../store/Hook"
import { getUser, isLogged, logout, setUser } from "../store/slices/utilisateurSlice"
import Sprite from "../components/Avatar/Sprite"
import StatisticCard from "../components/cards/StatisticCard"
import { Stats, statService } from "../store/services/statService"
import Presentation from "../components/profil/Presentation"
import { rankingService, Ranks } from "../store/services/rankingService"
import { utilisateurService } from "../store/services/utilisateurService"

const Profil: React.FunctionComponent = () => {

    const navigate = useNavigate()

    const user = useStoreSelector(getUser)
    const isConnected = useStoreSelector(isLogged)

    const dispatch = useStoreDispatch()

    const [statsJoueur, setStatsJoueur] = useState<Stats>(undefined)
    const [monthlyRank, setMonthlyRank] = useState<Ranks>(undefined)

    const [getCurrentUser] = utilisateurService.useGetUtilisateurMutation()
    const [getStats] = statService.useGetStatsMutation()
    const [getRanks] = rankingService.useGetMonthlyRankingMutation()

    function disconnect() {
        // console.log("déconnexion du joueur ", joueur.pseudo, " en cours...");
        dispatch(logout())
        navigate("/")
    }

    useEffect(() => {
        if (!user?.id) {
            dispatch(logout())
            navigate("/")
        } else {
            getCurrentUser()
                .unwrap()
                .then(user => dispatch(setUser(user)))
        }
    }, [user.id, user.elo, navigate, getCurrentUser, dispatch])

    useMemo(() => {
        if (!user?.elo) {
            return
        }

        getStats()
            .unwrap()
            .then((stats) => {
                if (stats.length > 0) {
                    setStatsJoueur(stats[0])
                }

            })

        if (isConnected) {
            getRanks(user.id)
                .unwrap()
                .then((ranks) => setMonthlyRank(ranks))
        }
    }, [user.id, user.elo, getRanks, getStats, isConnected])

    function handleInscription() {
        navigate("/register")
    }

    return (
        <div className="BabyfootArenas-body">
            <div className="home-link">
                <div className="profil">
                    <div className="profil-up">
                        <div className="profil-avatar">
                            <Sprite url={user?.avatar?.image?.url} options={{ tShirtColor: "#771610", etat: "win" }} />
                        </div>
                        <div className="profil-info">
                            <span className="profil-pseudo">{user?.username}</span>
                            {/* TODO : MàJ dynamique de l'elo du joueur joueur?.elo*/}
                            <span className="profil-elo">Niveau 1</span> 
                            {" "}
                            {/*{user?.elo}pts</span> */}
                            <button className="modify-button" onClick={() => navigate("/playerSelection")}>
                                Modifier
                                votre profil
                            </button>
                            <button className="modify-button" onClick={() => disconnect()}>Se déconnecter</button>
                            <div className="profil-stat">
                                <StatisticCard label="arena" stat={statsJoueur?.nb_arena} />
                                <StatisticCard label="partie" stat={statsJoueur?.nb_game} />
                                <StatisticCard label="victoire" stat={statsJoueur?.nb_victory} />
                            </div>
                        </div>
                    </div>
                    <div className="profil-presentation">

                        {
                            !isConnected &&
                            <button type="button" onClick={handleInscription}>Créer un compte</button>
                        }

                        {
                            isConnected &&
                            <Presentation ranks={monthlyRank} />
                        }

                        {/* <br/> */}
                        {/* &nbsp; */}
                        {/* 
                        <div className="gameHistoProfil">(Historique des parties à venir...)</div> */}
                        

                    </div>
                </div>

                <MenuNavigation />
            </div>

        </div>
    )
}
export default Profil

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { logout } from "../slices/utilisateurSlice"
import { RootState } from "../store"

export const baseUrl = `${process.env.REACT_APP_BACK_PROTOCOL_API}://${process.env.REACT_APP_BACK_URL}`

const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const jwt = (getState() as RootState).auth.jwt
        if (jwt) {
            headers.set("Authorization", `Bearer ${jwt}`)
        }
        return headers
    },
})

const fetchBaseQueryWithToken: ReturnType<typeof fetchBaseQuery> = async (
    args,
    api,
    extra
) => {
    const requestResult = await baseQuery(args, api, extra)

    if (requestResult.error && requestResult.error.status === 401) {
        api.dispatch(logout())
    }

    return requestResult
}

export const api = createApi({
    baseQuery: fetchBaseQueryWithToken,
    endpoints: () => ({}),
    tagTypes: [],
})

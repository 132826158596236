import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Credentials } from "../../types/Credentials"
import { RootState } from "../store"
import { Utilisateur } from "../services/utilisateurService"


const storeCredentials = (credentials: Credentials) => {
    if (credentials !== undefined) {
        localStorage.setItem("user", JSON.stringify(credentials))
    } else {
        localStorage.removeItem("user")
    }
}

const getInitialState = (): Credentials => {
    const user = JSON.parse(localStorage.getItem("user"))
    return user ? user : {}
}

const initialState = getInitialState()

export const utilisateurSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action: PayloadAction<Credentials>) => {
            state.jwt = action.payload.jwt
            state.user = action.payload.user
            storeCredentials(state)
        },
        logout: (state) => {
            state.jwt = null
            state.user = null
            storeCredentials(state)
        },
        setUser: (state, action: PayloadAction<Utilisateur>) => {
            state.user = action.payload
            storeCredentials(state)
        },
        setElo: (state, action: PayloadAction<number>) => {
            state.user.elo = action.payload
            storeCredentials(state)
        },
    },
})

export const { login, logout, setUser, setElo } = utilisateurSlice.actions

export const isLogged = (state: RootState): boolean => {
    return state.auth?.jwt !== undefined && state.auth?.jwt !== null
}

export const getUser = (state: RootState): Utilisateur => {
    return state.auth?.user
}

import React, { useEffect, useRef } from "react"

type CountdownTimerProps = {
    startDate: Date,
    onTimerEnd: () => void,
}

// March constant TIMER_EQUIPE_CREATION back
const TIMER_EQUIPE_CREATION_SECONDE = 2 * 60

const CountdownTimer: React.FunctionComponent<CountdownTimerProps> = ({ startDate, onTimerEnd }) => {

    const [countdown, setCountdown] = React.useState<number>(undefined)
    const [initialDelay, setInitialDelay] = React.useState<number>(undefined)
    const counterRef = useRef(10)

    useEffect(() => {
        counterRef.current = countdown
    })

    const connectionInterval = useRef<NodeJS.Timer>(undefined)

    useEffect(() => {
        if (startDate !== undefined) {
            clearInterval(connectionInterval.current)
            const secondsSinceStart = Math.round(startDate.getTime() / 1000)
            const secondsSinceNow = Math.round(Date.now() / 1000)

            const diff = TIMER_EQUIPE_CREATION_SECONDE - (secondsSinceNow - secondsSinceStart)
            if (diff <= TIMER_EQUIPE_CREATION_SECONDE) {
                setInitialDelay(diff)
                setCountdown(diff)


                connectionInterval.current = setInterval(() => {

                    if (counterRef.current > 0) {
                        setCountdown(counterRef.current - 1)
                    } else {
                        clearInterval(connectionInterval.current)
                        onTimerEnd()
                    }
                }, 1000)
            }
        } else {
            clearInterval(connectionInterval.current)
        }

        return () => {
            clearInterval(connectionInterval.current)
        }
    }, [startDate, onTimerEnd])

    return (
        <div id="countdown">
            <div id="countdown-number">{counterRef.current}</div>
            <svg style={{ width: "24vw", height: "24vw" }} viewBox="0 0 100 100">
                <circle
                    r="48"
                    cx="50"
                    cy="50"
                    stroke="black"
                    fill="white"
                    style={
                        countdown > 0 ? { animation: `countdown ${initialDelay + 1}s linear infinite forwards` } : {}
                    }>
                    
                </circle>
            </svg>
        </div>
    )
}

export default CountdownTimer

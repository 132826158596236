import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { Game } from "../services/gameService"

interface StoredGame {
    game?: Game;
}

const storeGame = (game: string) => {
    localStorage.setItem("game", game)
}

const getInitialState = (): StoredGame => {
    const game = JSON.parse(localStorage.getItem("game"))
    return game ? game : {}
}

const initialState = getInitialState()
export const gameSlice = createSlice({
    name: "gameSlice",
    initialState,
    reducers: {
        setGame: (state, action: PayloadAction<Game>) => {
            state.game = action.payload
            storeGame(JSON.stringify(state))
        },
        updateScoreBleu: (state, action: PayloadAction<number>) => {
            if (state.game) {
                state.game.score_bleu = action.payload
                storeGame(JSON.stringify(state))
            }
        },
        updateScoreRouge: (state, action: PayloadAction<number>) => {
            if (state.game) {
                state.game.score_rouge = action.payload
                storeGame(JSON.stringify(state))
            }
        },
        clearGame: (state) => {
            state.game = undefined
            localStorage.removeItem("game")
        },
    },
})

export const {
    setGame,
    updateScoreBleu,
    updateScoreRouge,
    clearGame,
} = gameSlice.actions

export const getGame = (state: RootState): Game | null => {
    return state.gameSlice?.game
}
